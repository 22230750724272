import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadEvents, clearEvents } from '../store/entities/events'
import { setCurrentCustomer, getBrands, getExtensionUsers, getExtensionUsersCount, setInfo, getLogo } from '../store/entities/customers'
import { withRouter, matchPath } from 'react-router-dom';
import { escapeDropdown, globalViewText } from '../utilities/config'

import logoWithText from '../assets/pixm-blue-on-white-logo-02@3x.png'
import OrgSvg from '../assets/org.svg'
import supportSvg from '../assets/support.svg'
import SelectBox from '../components/common/selectbox/selectBox'
import ProfilePopover from '../components/common/popover/profilePopover'
import './navbar.scss'
import { GLOBAL_VIEW_DISABLED_PATHS , GLOBAL_VIEW_DISABLED_MESSAGE} from '../utilities/globalViewDisabled';

class Navbar extends Component {
  state = {
    showDropdown: true,
    isPA : false,
  }

  componentDidMount(){
    this.loadLogo()
  }

  componentDidUpdate(prevProps) {    
    if(this.props !== prevProps){
      if(this.props.history.location.pathname !== "/login") {
        let match2;
        let match3;
        let match1 = matchPath(this.props.history.location.pathname, {
          path: '/customers/:param1/incidents',
          exact: true,
          strict: false
        })

        if(!match1){
          match2 = matchPath(this.props.history.location.pathname, {
            path: '/customers/:param1/incidents/:param2',
            exact: true,
            strict: false
          })
        }

        if (!match1 || !match2) {
          match3 = matchPath(this.props.history.location.pathname, {
            path: '/tenants/:param1/incidents/',
            exact: true,
            strict: false
          })
        }
        let match =  match1 || match2 || match3;
        if(match) {
          let filtredElement = this.props.orgList.filter((ele) => ele.id === match.params.param1)
          
          if(filtredElement && filtredElement instanceof Array && filtredElement.length > 0 && filtredElement[0].id !== this.props.selectedCustomer.id) {
            this.props.setCurrentCustomer(filtredElement[0]);
          }
        }
      }
    }
  }

  loadLogo = () => {
    let body = {
      domainName : this.props.domain,
      fileName : "logo-medium-size.png"
    }
    if(this.props.tokenRoles =="PixmAdmin")
      {
          this.setState({
            ...this.state,
            isPA : true,
          })
      }
    else{
      this.props.getLogo(body);
    }
  }

  renderSelectedCustomerData = (selectedCustomer) => {
    let { pathname } = this.props.history.location
    let isGlobalView = selectedCustomer.organizationName === globalViewText;
    switch (true) {
      case pathname.includes('incidents'):
        return `${isGlobalView ? '/tenants' : '/customers'}/${selectedCustomer.id}/incidents`

      case pathname.includes('add-admin'):
          return `/customers/${selectedCustomer.id}/settings/add-admin`
        
      case pathname.includes('advanced-settings'):
            return `/advanced-settings`
            
      case pathname.includes('settings'):
          return `/customers/${selectedCustomer.id}/settings`

      case pathname.includes('license-management'):
          return `${isGlobalView ? '/tenants' : '/customers'}/${selectedCustomer.id}/license-management`  

      case pathname.includes('licensemanagement'):
          return `${isGlobalView ? '/tenants' : '/customers'}/${selectedCustomer.id}/license-management`

      case pathname.includes('deployment'):
          return `${isGlobalView ? '/tenants' : '/customers'}/${selectedCustomer.id}/deployment`
      
      case pathname.includes('soc-integration'):
        return `/soc-integration`
    }
  }

  handleSelect = (customerDetails) => {
    const { selectedCustomer, setInfo, setCurrentCustomer, clearEvents, history } = this.props;

    let { location } = history;
    let path = location.pathname.split('/').pop();
    let isGlobalView = customerDetails.organizationName === globalViewText;
    const { multiGroup } = customerDetails;

    const isLicenseOrSoc = path === 'license-management' || path === 'soc-integration';
    const shouldRedirect = (isGlobalView && GLOBAL_VIEW_DISABLED_PATHS.includes(path)) || (isLicenseOrSoc && multiGroup);

    if (shouldRedirect) {
      // show message and redirect to incidents when global is selected when the location is settings
      setInfo(`${GLOBAL_VIEW_DISABLED_MESSAGE} ${path}`)
      history.push(`/tenants/${customerDetails.id}/incidents`)
    }
    setCurrentCustomer(customerDetails);
    history.push(this.renderSelectedCustomerData(customerDetails))
  }

  handleLogoClick = () => {
    let { selectedCustomer } = this.props
    let isGlobalView = selectedCustomer.organizationName === globalViewText;
    this.props.history.push(`${isGlobalView ? '/tenants' : '/customers'}/${selectedCustomer.id}/incidents`)
  }

  render() {
    const { userName, isLoggedIn, orgList } = this.props

    let preventClick = true
    if(this.props.selectedCustomer) {
      preventClick = false
    }

    if(this.props.location && this.props.tokenRoles === 'TenantAdmin'){
      escapeDropdown.map(item => {
        if (this.props.location.pathname.includes(item)) {
          this.state.showDropdown = false
        } else {
          this.state.showDropdown = true
        }
      });
    }
    return (
      <div className="pixm-nav">
        <nav className="navbar navbar-expand-lg navbar-light bg-none d-flex navContainer">
          <span className={`navbar-brand ${!isLoggedIn ? 'flex-grow-1' : ''}`}>
            {this.state.isPA ? <img
              style={{cursor:'pointer'}}
              onClick={ () => !preventClick ? this.handleLogoClick() : null}
              src={logoWithText}
              height="40"
              className="d-inline-block align-top"
              alt=""
            /> : !this.props.logoLoading ? 
            <img
              style={{cursor:'pointer'}}
              onClick={ () => !preventClick ? this.handleLogoClick() : null}
              src={this.props.logo}
              height="40"
              className="d-inline-block align-top"
              alt=""
            /> : <div className='loader'></div> }
          </span>
          {isLoggedIn && (
            <div
              style={{ margin: '0 10vw', position: 'relative' }}
              className="d-flex flex-grow-1 align-items-center"
            >
            </div>
          )}
          {isLoggedIn && this.props.tokenRoles === 'CustomerAdmin' && (
            <div
              style={{ padding: '0 0.6vw', fontSize: '16px' , marginRight: "135px"}}
            >
              <img
                style={{ width: '22px', marginTop: '-2px' }}
                src={OrgSvg}
                alt=""
              />
              {this.props.selectedCustomer.organizationName}
            </div>
          )}
          {!isLoggedIn && (
            <div className="cursor-pointer d-flex align-items-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.pixm.net"
                style={{ color: '#000' }}
              >
                Support
                <img
                  style={{ width: '2.2vw' }}
                  src={supportSvg}
                  alt=""
                  srcSet=""
                />
              </a>
            </div>
          )}
          {isLoggedIn && (
            <React.Fragment>
              { this.state.showDropdown && (this.props.tokenRoles !== 'CustomerAdmin') && (<SelectBox
                role="CustomerAdmin,PixmAdmin,TenantAdmin"
                width="300px"
                optionBoxWidth="250px"
                options={orgList}
                onSelect={this.handleSelect}
                icon={OrgSvg}
                iconClass=""
                type="org"
                showSearch={true}
              />) }
              <div className='userName'>
                {userName}
              </div>

              <ProfilePopover {...this.props} />
            </React.Fragment>
          )}
        </nav>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userName: state.entities.user.name,
  tokenRoles: state.entities.user.roles,
  isLoggedIn: state.entities.user.isLoggedIn,
  filters: state.entities.events.filters,
  orgList: state.entities.customers.list,
  selectedCustomer: state.entities.customers.selected,
  logo : state.entities.customers.logo,
  logoLoading : state.entities.customers.logoLoading,
  domain : state.entities.user.domain
})

const mapDispatchToProps = (dispatch) => ({
  // loadEvents: (filters, selectedId, isGlobalView) => dispatch(loadEvents(filters, selectedId, isGlobalView)),
  setCurrentCustomer: (selectedId, isGlobalView) => dispatch(setCurrentCustomer(selectedId, isGlobalView)),
  // getBrands:(selectedId, isGlobalView) => dispatch(getBrands(selectedId, isGlobalView)),
  clearEvents: () => dispatch(clearEvents()),
  // getExtensionUsers:(selectedId, isGlobalView) => dispatch(getExtensionUsers(selectedId, isGlobalView)),
  // getExtensionUsersCount: (selectedId, isGlobalView) => dispatch(getExtensionUsersCount(selectedId, isGlobalView)),
  setInfo: (error) => dispatch(setInfo(error)),
  getLogo: (body) => dispatch(getLogo(body)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
