import React, { Component } from 'react'
import { connect } from 'react-redux'
import { produce } from 'immer'

import {
  loadEvents,
  loadLoginPagesScannedEvents,
  loadThreatsDetected,
  loadTopThreats,
  loadTopVulnerable,
  setChartFilterInStore,
  setThreatFiltersInStore
} from '../store/entities/events'
import SelectBox from './common/selectbox/selectBox'
import { getUTCDate } from '../utilities/datetime'
import { isGlobalView } from '../utilities/config'
import ProfileSvg from '../assets/profile.svg'
import BrandSvg from '../assets/Badge.svg'
import CalendarSvg from '../assets/calendar.svg'
import StatusSvg from '../assets/update.svg'
import ListSvg from '../assets/list.svg'
import ListSelectedSvg from '../assets/list_selected.svg'
import GridSvg from '../assets/grid.svg'
import GridSelectedSvg from '../assets/grid_selected.svg'
import ChartImg from '../assets/ChartImg.png'
import ChartSelected from '../assets/ChartSelected.png'
import FilterPopover from './common/popover/filterPopover'
import { eventsInitialState } from '../store/initialState/eventsState'
import './incidentFilter.scss'
import { eventTypes } from '../utilities/eventTypes'

const dateRangeMap = {
  30: { name: 'Last 30 days', value: getUTCDate(30), id: getUTCDate(30) },
  60: { name: 'Last 60 days', value: getUTCDate(60), id: getUTCDate(60) },
  90: { name: 'Last 90 days', value: getUTCDate(90), id: getUTCDate(90) },
  365: { name: 'Last 1 year', value: getUTCDate(365), id: getUTCDate(365) },
  1095: {
    name: 'Last 3 years',
    value: getUTCDate(3 * 365),
    id: getUTCDate(3 * 365),
  },
}

class IncidentFilter extends Component {
  state = {
    customerChanged: false,
    threatFilters: this.props.threatFilters,
    chartFilters: this.props.chartFilters,
  }

  rangeOptions = [
    { name: 'Last 30 days', value: getUTCDate(30), id: getUTCDate(30) },
    { name: 'Last 60 days', value: getUTCDate(60), id: getUTCDate(60) },
    { name: 'Last 90 days', value: getUTCDate(90), id: getUTCDate(90) },
    { name: 'Last 1 year', value: getUTCDate(365), id: getUTCDate(365) },
    {
      name: 'Last 3 years',
      value: getUTCDate(3 * 365),
      id: getUTCDate(3 * 365),
    },
  ]

  statusOptions = [
    { name: 'Status: All', value: 'all', id: 'all' ,eventType : [eventTypes.incident , eventTypes.reported_phish]},
    { name: 'Verified', value: '1', id: '1', eventType : [eventTypes.incident , eventTypes.reported_phish]},
    { name: 'Suspicious', value: '0', id: '0',eventType : [eventTypes.incident] },
    { name : 'Reported Phish' ,  value :'2' ,id : '2', eventType : [eventTypes.reported_phish]}
  ]

  componentDidMount() {
    this.initailizeFilters()   
  }

  componentDidUpdate(prevProps) {
    let { selectedCustomer } = this.props
    if (this.props.selectedCustomer !== prevProps.selectedCustomer) {
      this.state.customerChanged = true
      this.initailizeFilters()
    }
  }

  initailizeFilters = () => {
    this.setState({
      threatFilters: eventsInitialState.threat.filters,
      chartFilters: eventsInitialState.chart.filters,
    })
  }

  handleCustomerChange = () => {
    this.state.customerChanged = false
  }

  removeFilter = (key, filterType) => {
    if (filterType === 'threatFilters') {
      this.state.threatFilters = produce(this.state.threatFilters, (draftState) => {
        delete draftState[key]
      })
      this.props.setThreatFiltersInStore(this.state.threatFilters)
    } else {
      this.state.chartFilters = produce(this.state.chartFilters, (draftState) => {
        delete draftState[key]
      })
      this.props.setChartFilterInStore(this.state.chartFilters)
    }
  }

  setFilters = (changedValues, filterType) => {
    if (filterType === 'threatFilters') {
      this.state.threatFilters = produce(this.state.threatFilters, (draftState) => {
        Object.keys(changedValues).map((property) => {
          draftState[property] = changedValues[property]
        })
      })
      this.props.setThreatFiltersInStore(this.state.threatFilters)
    } else {
      this.state.chartFilters = produce(this.state.chartFilters, (draftState) => {
        Object.keys(changedValues).map((property) => {
          draftState[property] = changedValues[property]
        })
      })
      this.props.setChartFilterInStore(this.state.chartFilters)
    }
  }

  loadLoginData = () => {
    let objFilter = {}
    objFilter['startTime'] = this.state.chartFilters.startTime
    objFilter['endTime'] = this.state.chartFilters.endTime
    objFilter['requireTotalRecordsCount'] = 1
    objFilter['numberOfBuckets'] = 9
    objFilter['r'] = isGlobalView(this.props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['cKey'] = this.props.selectedCustomer.key;
    this.setFilters(objFilter,'chartFilters')
    this.props.loadLoginPagesScannedEvents(
      objFilter,
      this.props.selectedCustomer.id,
      this.props.selectedCustomer.defaultCustomerId
    )
    this.removeFilter('r','chartFilters')
  }

  loadThreatDetected = (triagedEvent) => {
    let objFilter = {}
    this.removeFilter('removeThreatUrl','chartFilters')
    this.removeFilter('requireTotalRecordsCount','chartFilters')
    this.removeFilter('includeFalsePositive','chartFilters')
    objFilter['type'] = [eventTypes.incident]
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['chartType'] = 'threatDetected';
    objFilter['numberOfBuckets'] = 9;
    objFilter['r'] = isGlobalView(this.props.selectedCustomer.organizationName)
    ? 'M'
    : 'C'
    objFilter['offset'] = 0;
    objFilter['cKey'] = this.props.selectedCustomer.key;
    this.setFilters(objFilter,'chartFilters')
    this.props.loadThreatsDetected(
      this.state.chartFilters,
      this.props.selectedCustomer.id,
      this.props.selectedCustomer.defaultCustomerId,
      isGlobalView(this.props.selectedCustomer.organizationName),
    )
    this.removeFilter('numberOfBuckets','chartFilters')
    this.removeFilter('chartType','chartFilters')
  }

  loadTopThreats = (triagedEvent) => {
    let objFilter = {}
    this.removeFilter('removeThreatUrl','chartFilters')
    this.removeFilter('requireTotalRecordsCount','chartFilters')
    this.removeFilter('includeFalsePositive','chartFilters')
    objFilter['type'] = [eventTypes.incident]
    objFilter['chartType'] = 'topthreats';
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['r'] = isGlobalView(this.props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['offset'] = 0;
    objFilter['fetchOnlyRecords'] = 13;
    objFilter['cKey'] = this.props.selectedCustomer.key;
    this.setFilters(objFilter,'chartFilters')
    this.props.loadTopThreats(
      this.state.chartFilters,
      this.props.selectedCustomer.id,
      this.props.selectedCustomer.defaultCustomerId,
      isGlobalView(this.props.selectedCustomer.organizationName),
    )
    this.removeFilter('chartType','chartFilters')
  }

  loadTopVulnerable = (triagedEvent) => {
    let objFilter = {}
    this.removeFilter('removeThreatUrl','chartFilters')
    this.removeFilter('requireTotalRecordsCount','chartFilters')
    this.removeFilter('includeFalsePositive','chartFilters')
    objFilter['type'] = [eventTypes.incident]
    objFilter['chartType'] = 'topvulnerable';
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['r'] = isGlobalView(this.props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['offset'] = 0;
    objFilter['fetchOnlyRecords'] = 13;
    objFilter['cKey'] = this.props.selectedCustomer.key;
    this.setFilters(objFilter,'chartFilters') 
    this.props.loadTopVulnerable(
      this.state.chartFilters,
      this.props.selectedCustomer.id,
      this.props.selectedCustomer.defaultCustomerId,
      isGlobalView(this.props.selectedCustomer.organizationName),
    )
    this.removeFilter('chartType','chartFilters')
  }


  handleSelect = (selectedOption, value) => {
    let { selectedCustomer, viewType } = this.props
    let objThreatFilters = {}
    let objChartFilter = {}

    const threatFilters = this.props.threatFilters
    const chartFilters = this.props.chartFilters
    if (selectedCustomer) {
      if(viewType !== 'chart') {
        objThreatFilters['pageSize'] = 21
        objThreatFilters['offset'] = 0
        threatFilters.hasOwnProperty('removeRepeateUrl')
          ? (objThreatFilters['removeRepeateUrl'] = true)
          : this.removeFilter('removeRepeateUrl','threatFilters')
        threatFilters.hasOwnProperty('removeThreatUrl')
          ? (objThreatFilters['removeThreatUrl'] = true)
          : this.removeFilter('removeThreatUrl','threatFilters')
        threatFilters.hasOwnProperty('includeFalsePositive')
          ? (objThreatFilters['includeFalsePositive'] = true)
          : this.removeFilter('includeFalsePositive','threatFilters')

        if (selectedOption.type === 'range') {
          objThreatFilters['startTime'] = selectedOption.value
          objThreatFilters['endTime'] = getUTCDate(0)
        }
        if (selectedOption.type === 'status') {
          objThreatFilters['type'] = selectedOption.eventType;

          if (selectedOption.value === 'all' || selectedOption.value === '2') this.removeFilter('triagedEvent','threatFilters')
          if (selectedOption.value != 'all') objThreatFilters['triagedEvent'] = selectedOption.value

        }

        if (selectedOption.type === 'brand') {
          if (selectedOption.value === 'allbrands') this.removeFilter('brand','threatFilters')
          else objThreatFilters['brand'] = selectedOption.value
        }

        if (selectedOption.type === 'users') {
          if (selectedOption.value === 'allusers')
            this.removeFilter('extensionUserId','threatFilters')
          else objThreatFilters['extensionUserId'] = selectedOption.value
        }
        this.setFilters(objThreatFilters, "threatFilters")
        this.props.loadEvents(
          this.state.threatFilters,
          selectedCustomer.id,
          selectedCustomer.defaultCustomerId,
          isGlobalView(selectedCustomer.organizationName)
        )
      } else {
        objChartFilter['offset'] = 0
        chartFilters.hasOwnProperty('removeRepeateUrl')
          ? (objChartFilter['removeRepeateUrl'] = true)
          : this.removeFilter('removeRepeateUrl','chartFilters')
        chartFilters.hasOwnProperty('removeThreatUrl')
          ? (objChartFilter['removeThreatUrl'] = true)
          : this.removeFilter('removeThreatUrl','chartFilters')
        chartFilters.hasOwnProperty('includeFalsePositive')
          ? (objChartFilter['includeFalsePositive'] = true)
          : this.removeFilter('includeFalsePositive','chartFilters')

        if (selectedOption.type === 'range') {
          objChartFilter['startTime'] = selectedOption.value
          objChartFilter['endTime'] = getUTCDate(0)
        }
        if (selectedOption.type === 'status') {
          objChartFilter['type'] = selectedOption.eventType;

          if (selectedOption.value === 'all' || selectedOption.value === '2') this.removeFilter('triagedEvent','chartFilters')
          if (selectedOption.value != 'all')  objChartFilter['triagedEvent'] = selectedOption.value
        }

        if (selectedOption.type === 'users') {
          if (selectedOption.value === 'allusers')
            this.removeFilter('extensionUserId','chartFilters')
          else objChartFilter['extensionUserId'] = selectedOption.value
        }
        this.setFilters(objChartFilter,'chartFilters')
        let triagedEvent = null
        if (this.state.chartFilters.hasOwnProperty('triagedEvent')) {
          triagedEvent = this.state.chartFilters['triagedEvent']
        }        
        this.loadLoginData()
        this.loadThreatDetected(triagedEvent);
        this.loadTopThreats(triagedEvent);
        this.loadTopVulnerable(triagedEvent);
      }
    }
  }

  render() {
    const { viewType, onViewSelect, brandsList, usersList } = this.props
    const filters = this.props.viewType === 'chart' ? this.props.chartFilters : this.props.threatFilters
    let selectedTriagedOption = null
    let selectedExtensionUser = null
    let selectedBrand = null
    let selectedDateRange = null
    if (filters.hasOwnProperty('triagedEvent')) {
      let selected = this.statusOptions.filter(
        (item) => item.id == filters.triagedEvent,
      )
      selectedTriagedOption = selected[0]
    }    
    if (filters.hasOwnProperty('extensionUserId')) {
      let selected = usersList.filter(
        (item) => item.id == filters.extensionUserId,
      )
      selectedExtensionUser = selected[0]
    }
    if (filters.hasOwnProperty('brand')) {
      let selected = brandsList.filter((item) => item.name == filters.brand)
      selectedBrand = selected[0]
    } else {
      selectedBrand = brandsList[0]
    }
    if (
      filters.hasOwnProperty('startTime') &&
      filters.hasOwnProperty('endTime')
    ) {
      let firstDate = new Date(filters.endTime)
      let secondDate = new Date(filters.startTime)
      let diffDate = Math.floor(
        (Date.UTC(
          firstDate.getFullYear(),
          firstDate.getMonth(),
          firstDate.getUTCDate(),
        ) -
          Date.UTC(
            secondDate.getFullYear(),
            secondDate.getMonth(),
            secondDate.getUTCDate(),
          )) /
          (1000 * 60 * 60 * 24),
      )
      selectedDateRange = dateRangeMap[diffDate]
    }
    
    return (
      <div className="Mask">
        <div className="filterBar">
          <SelectBox
            role="CustomerAdmin,PixmAdmin,TenantAdmin"
            width="220px"
            optionBoxWidth="220px"
            selectedOption={
              !this.state.customerChanged ? selectedExtensionUser : null
            }
            options={usersList}
            defaultOption={usersList[0]}
            onSelect={this.handleSelect}
            icon={ProfileSvg}
            customerChanged={this.state.customerChanged}
            handleCustomerChange={this.handleCustomerChange}
            //iconClass="mr-3"
            type="users"
            showSearch={true}
          />
          <div className="separator"></div>
          <SelectBox
            role="CustomerAdmin,PixmAdmin,TenantAdmin"
            width="220px"
            optionBoxWidth="220px"
            selectedOption={!this.state.customerChanged ? selectedBrand : null}
            options={brandsList}
            defaultOption={brandsList[0]}
            customerChanged={this.state.customerChanged}
            onSelect={this.handleSelect}
            handleCustomerChange={this.handleCustomerChange}
            icon={BrandSvg}
            //iconClass="mr-3"
            type="brand"
            showSearch={true}
          />
          <div className="separator"></div>
          <SelectBox
            role="CustomerAdmin,PixmAdmin,TenantAdmin"
            width="220px"
            optionBoxWidth="220px"
            selectedOption={
              !this.state.customerChanged ? selectedDateRange : null
            }
            options={this.rangeOptions}
            defaultOption={this.rangeOptions[0]}
            customerChanged={this.state.customerChanged}
            onSelect={this.handleSelect}
            handleCustomerChange={this.handleCustomerChange}
            icon={CalendarSvg}
            //iconClass="mr-3"
            type="range"
          />
          {this.props.tokenRoles === 'PixmAdmin' && (
            <div className="separator"></div>
          )}
          <SelectBox
            role="PixmAdmin"
            width="220px"
            optionBoxWidth="220px"
            selectedOption={
              !this.state.customerChanged ? selectedTriagedOption : null
            }
            options={this.statusOptions}
            defaultOption={this.statusOptions[0]}
            customerChanged={this.state.customerChanged}
            onSelect={this.handleSelect}
            handleCustomerChange={this.handleCustomerChange}
            icon={StatusSvg}
            //iconClass="mr-3"
            type="status"
          />
          <div className="separator"></div>
          <div className="filters">
            <FilterPopover />
          </div>
          <div className="separator"></div>
          <div className="viewName">
            {/* <span>{viewType === 'grid' ? 'Grid View' : 'List View'}</span> */}
          </div>
          <div className="rightSection">
            <div className="separator"></div>
            <div className="viewIcons">
              <div title="List view">
                <img
                  src={viewType === 'list' ? ListSelectedSvg : ListSvg}
                  style={{ height: '25px' }}
                  alt=""
                  onClick={() => onViewSelect('list')}
                />
              </div>
            </div>
            <div className="separator"></div>
            <div className="viewIcons">
              <div title="Grid view">
                <img
                  src={viewType === 'grid' ? GridSelectedSvg : GridSvg}
                  style={{ height: '25px' }}
                  alt=""
                  onClick={() => onViewSelect('grid')}
                />
              </div>
            </div>
            {process.env.REACT_APP_SHOWCHARTS === 'true' && (
              <>
                <div className="separator"></div>
                <div className="viewIcons">
                  <div title="Chart view">
                    <img
                      src={viewType === 'chart' ? ChartSelected : ChartImg}
                      style={{ height: '15px', width: '18px' }}
                      onClick={() => onViewSelect('chart')}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isEventsLoadOnceIncidents: state.entities.events.isEventsLoadOnceIncidents,  
  selectedCustomer: state.entities.customers.selected,
  brandsList: state.entities.customers.brands,
  usersList: state.entities.customers.users,
  isEventsLoadOnceIncidents: state.entities.events.isEventsLoadOnceIncidents,
  orgList: state.entities.customers.list,
  tokenRoles: state.entities.user.roles,
  threatFilters: state.entities.events.threat.filters,
  chartFilters: state.entities.events.chart.filters,
})

const mapDispatchToProps = (dispatch) => ({
  setThreatFiltersInStore: (filters) => dispatch(setThreatFiltersInStore(filters)),
  loadEvents: (filters, selectedId, defaultCustomerId, isGlobalView) =>
    dispatch(loadEvents(filters, selectedId, defaultCustomerId, isGlobalView)),
  loadThreatsDetected: (filters, selectedId, defaultCustomerId, isGlobalView) =>
    dispatch(loadThreatsDetected(filters, selectedId, defaultCustomerId, isGlobalView)),
  loadLoginPagesScannedEvents: (offset, selectedId, defaultCustomerId) =>
    dispatch(loadLoginPagesScannedEvents(offset, selectedId, defaultCustomerId)),
  loadTopThreats : (filters ,selectedId, defaultCustomerId, isGlobalView) =>
    dispatch(loadTopThreats(filters, selectedId, defaultCustomerId, isGlobalView)),
  loadTopVulnerable : (filters, selectedId, defaultCustomerId, isGlobalView) => 
    dispatch(loadTopVulnerable(filters, selectedId, defaultCustomerId, isGlobalView)),
  setChartFilterInStore: (filters) => dispatch(setChartFilterInStore(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidentFilter)
